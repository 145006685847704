import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

export const meta: MetaFunction = () => [{ title: 'Neutrino Graphics' }]

export default function Index() {
	return (
		<main className="h-screen p-5">
			{/* Home Section */}
			<section
				id="home"
				className="flex h-screen snap-center items-center justify-center"
			>
				<div className="flex max-w-3xl flex-col space-y-10">
					<h1 className="text-3xl lg:text-5xl">
						Build the perfect website or business application
					</h1>
					<h2 className="text-md lg:text-xl">
						Whether you know exactly what you want or need some leading, we'll
						help you discover, design, and deploy the right software solution
						for your needs.
					</h2>
					<Link
						className="w-fit rounded bg-indigo-400 px-5 py-2 text-xl text-white transition-colors duration-200 hover:bg-indigo-700 lg:px-10 lg:py-5"
						to="/contact-us"
					>
						Contact Now
					</Link>
				</div>
			</section>

			{/* About Us Section */}
			<section
				id="about-us"
				className="h-screen snap-center lg:flex lg:items-center lg:justify-center"
			>
				<div className="flex max-w-6xl flex-col space-y-10 text-center">
					<h1 className="text-3xl lg:text-5xl">How do we work?</h1>
					<h2 className="text-md lg:text-xl">
						We believe that good software is both an art and a science. Our
						development processes are continually evolving to provided optimum
						creativity, stability, and accuracy.
					</h2>
					<ol className="flex snap-x snap-mandatory space-x-5 overflow-x-auto lg:justify-between lg:space-x-10">
						{workFlowProps.map((item, i) => (
							<li
								key={i}
								className="h-96 w-full min-w-[15rem] snap-center rounded border p-5 transition-colors duration-200 hover:bg-indigo-200 dark:hover:bg-indigo-400"
							>
								<h3 className="my-5 mt-10 text-xl lg:text-3xl">{item.title}</h3>
								<h4 className="text-sm lg:text-lg">{item.description}</h4>
							</li>
						))}
					</ol>
				</div>
			</section>

			<section id={'footer'} className="pb-5">
				<p>© 2021 All Rights Reserved Neutrino Graphics LLC</p>
			</section>
		</main>
	)
}

const workFlowProps = [
	{
		title: 'A Clear Vision',
		description:
			'A clear and measurable definition of your success is the first and most important step.',
	},
	{
		title: 'Rapid Iteration',
		description:
			'Your vision is executed in units so you can stay ahead of the curve and adjust your goals to meet your market.',
	},
	{
		title: 'Communication',
		description:
			'We’ll talk with you all along the way to make sure that the product stays true to your vision.',
	},
]
